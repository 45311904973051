import { t } from 'i18next'
import { FeatureFlagProvider } from '@touchbistro/node-common'
import { FeatureFlagProvider as FeatureFlagProviderForClient } from '@touchbistro/react-feature-flags'
import { Entitlement, Policies } from '~/src/shared/helpers/permissions'
import { LD_FLAG_VENUE_GROUPING_SELECTOR } from '~/src/shared/initializers/ld_client'
import { POSWebviewMessage, PoliciesForAccessibleBases } from '~/src/shared/types'

export const isPOSWebview = (incomingUserAgent: string): boolean => {
  return incomingUserAgent.startsWith('POS Webview')
}

export const SendPOSWebviewMessage = (message: POSWebviewMessage): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any
  if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.toggleMessageHandler) {
    win.webkit.messageHandlers.toggleMessageHandler.postMessage({
      message,
    })
  } else if (win.ReactNativeWebView) {
    win.ReactNativeWebView.postMessage(
      JSON.stringify({
        message,
      })
    )
  } else {
    throw new Error('windows webkit/reactnative is not able to post message.')
  }
}

export const getUserRootPolicies = (
  policiesForAccessibleBases: PoliciesForAccessibleBases,
  rootBaseXRefID?: string
): {
  [policy in Policies]?: boolean
} => {
  return rootBaseXRefID
    ? Object.entries(policiesForAccessibleBases).reduce((acc, [policy, bases]) => {
        if (bases.includes(rootBaseXRefID)) {
          return {
            ...acc,
            [policy]: true,
          }
        }
        return acc
      }, {})
    : {}
}

// Enable groups-behaviour if the base has either RMM3 and/or POS_STAFF entitlements.
// This check will be deprecated once all customers have been upgraded
// i.e. all hierarchies have groups with POS_STAFF and RMM3 entitlement.
export const isVenueGroupingEnabledForClient = ({
  featureFlagContext,
  baseEntitlements,
}: {
  featureFlagContext: { client: FeatureFlagProviderForClient } | undefined
  baseEntitlements: Entitlement[]
}): boolean => {
  if (featureFlagContext == null) {
    return false
  }

  if (
    baseEntitlements.includes(Entitlement.SIMPLIFIED_MENU) ||
    baseEntitlements.includes(Entitlement.POS_STAFF)
  ) {
    return featureFlagContext.client.variation(LD_FLAG_VENUE_GROUPING_SELECTOR, false)
  }

  // For all legacy bases, do not show groups-behaviour
  return false
}

export const isVenueGroupingEnabledForServer = async ({
  featureFlagProvider,
  baseEntitlements,
  baseXRefID,
}: {
  featureFlagProvider: FeatureFlagProvider
  baseEntitlements: Entitlement[]
  baseXRefID: string
}): Promise<boolean> => {
  if (
    baseEntitlements.includes(Entitlement.SIMPLIFIED_MENU) ||
    baseEntitlements.includes(Entitlement.POS_STAFF)
  ) {
    return await featureFlagProvider.variation(
      // Can't import FeatureFlag enum from ld_server
      // so hardcoding the FF key here.
      'ark.base-selector-venue-grouping',
      {
        anonymous: false,
        key: `baseXRefID-${baseXRefID}`,
      },
      false
    )
  }

  // For all legacy bases, do not show groups-behaviour
  return false
}

/**
 * Calculates a human readable string for pagination info
 * @param total pagination total pages
 * @param current pagination current page
 * @param limit pagination limit of results per page
 * @returns a human readable string. Eg: 1-3 of 14
 */
export const paginationShowEntriesText = (
  total: number,
  current: number,
  limit: number
): string => {
  return total === 0
    ? `${t('showEntries', {
        from: 0,
        to: 0,
        total: 0,
      })}`
    : `${t('showEntries', {
        from: current * limit + 1,
        to: (current + 1) * limit > total ? total : (current + 1) * limit,
        total,
      })}`
}
