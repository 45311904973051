const venueDetailsEN = {
  'acc.venue_info': 'Venue Info',
  'acc.tax_settings': 'Tax Settings',
  'payment_settings.payment_settings': 'Payment Settings',
  'payment_settings.agreements': 'Terms and Conditions',
  'payment_settings.agreements.acknowledge': 'I acknowledge and agree to the above',
  'payment_settings.agreements.offline_payments.payrix.body_1':
    "Enable this feature to store transactions in your payment device that can't be immediately processed in the event the payment gateway can't be reached (e.g., Internet is unavailable or the gateway is temporarily offline).",
  'payment_settings.agreements.offline_payments.payrix.body_2':
    'Your payment device has a default limit of storing $100 per transaction and a total limit of storing $1000 for all transactions. These limits can be adjusted. Once the limit has been reached, a popup will appear informing you that you have reached the maximum number of offline payments that can be stored on the card reader.',
  'payment_settings.agreements.offline_payments.payrix.body_3':
    'Stored card payments are not authorized (e.g., for credit availability, lost/stolen status) until connectivity returns and they are forwarded to the payment gateway. Stored payments may still be declined.',
  'payment_settings.agreements.offline_payments.payrix.body_4':
    'Disable Offline Payments if you do not want to accept this risk.',
  'payment_settings.save_success': 'Changes successfully saved!',
  'payment_settings.save_failure': 'Unable to save. Please try again.',
  'payment_settings.tip': 'Tip Prompt',
  'payment_settings.tip.description': 'Prompts for a tip on the terminal or receipt.',
  'payment_settings.tip.mode': 'Location of Tip Prompt',
  'payment_settings.tip.mode.options.terminal': 'Tip on Terminal',
  'payment_settings.tip.mode.options.receipt': 'Tip on Receipt',
  'payment_settings.tip.calculation': 'Tip Calculation',
  'payment_settings.tip.calculation.description':
    'Select whether the tip is calculated post-tax, pre-tax, pre-discount.',
  'payment_settings.tip.calculation.options.post_tax': 'Post-Tax',
  'payment_settings.tip.calculation.options.pre_tax': 'Pre-Tax',
  'payment_settings.tip.calculation.options.pre_discount': 'Pre-Discount',
  'payment_settings.tip.recommendation': 'Suggested Tip Amounts (%)',
  'payment_settings.tip.recommendation.description':
    'Specify the suggested tip amounts that will display on the terminal or receipt.',
  'payment_settings.offline_payments': 'Offline Payments',
  'payment_settings.offline_payments.description':
    'Ability to continue processing payments when offline by storing transaction records on the terminal.',
  'payment_settings.offline_payments.note':
    '** Offline Payments is only available for Tip on Terminal.',
  'payment_settings.offline_payments.agent_note':
    'Offline Payments is only editable by merchants. Please ask the merchant to make any required changes.',
  'payment_settings.offline_payments.max_per_transaction_limit': 'Per Transaction Limit',
  'payment_settings.offline_payments.max_per_transaction_limit.description':
    'Max transaction size when offline.',
  'payment_settings.offline_payments.max_total_limit': 'Total Limit',
  'payment_settings.offline_payments.max_total_limit.description':
    'Sum of all offline transaction amounts.',
  'payment_settings.offline_payments.per_terminal': 'per terminal',
  'payment_settings.pre_authorized_payments': 'Pre-Authorized Payments',
  'payment_settings.pre_authorized_payments.description':
    'Place a hold on a credit card at the beginning of an order to check that the card is valid.',
  'payment_settings.pre_authorized_payments.order_types': 'Order Types',
  'payment_settings.pre_authorized_payments.order_types.description':
    'Select which order types you would like to allow pre-authorizations for.',
  'payment_settings.pre_authorized_payments.order_types.options.bar_tabs': 'Bar Tabs',
  'payment_settings.pre_authorized_payments.order_types.options.tables': 'Tables',
  'payment_settings.pre_authorized_payments.amount': 'Authorized Amount',
  'payment_settings.pre_authorized_payments.amount.description':
    'Specify the initial amount to be held on the guest’s card.',
  'payment_settings.pre_authorized_payments.is_credit_card_mandatory': 'Make Credit Card Mandatory',
  'payment_settings.pre_authorized_payments.is_credit_card_mandatory.description':
    'A credit card must be pre-authorized in order to proceed with the order.',
  'payment_settings.batch_close': 'Batch Close',
  'payment_settings.batch_close.description':
    'Select when your batch of transactions will automatically close each day for settlement.',
  'payment_settings.batch_close.time': 'Time',
  'payment_settings.batch_close.guidance':
    'Tip: Select a batch time that is outside of your service hours for easier report reconciliation.',
  'payment_settings.errors.invalid_time': 'Please enter a valid time (e.g. 2:15pm)',
  'payment_settings.errors.min_max': 'Value must be between {{min}} and {{max}}',
  'payment_settings.update_pos_version_msg':
    'Please update your POS to the latest version for access to all available features.',
  'pos.receipt_settings': 'Receipt Settings',
  'pos.receipt_frequency': 'Receipt Frequency',
  'pos.receipt_frequency_info':
    'For each payment type, choose whether Merchant and Customer copies of the receipt should Always Print.\nAny unchecked options will Print on Prompt only.',
  'pos.receipt_frequency_info_ios':
    'Choose whether Merchant and Customer copies of the receipt should Always Print.\nAny unchecked options will Print on Prompt only.',
  'pos.print_frequency_never': 'Never Print',
  'pos.print_frequency_always': 'Always Print',
  'pos.print_frequency_prompt': 'Print on Prompt',
  'pos.payment_type': 'Payment Type',
  'pos.merchant_copy': 'Always Print Merchant Copy',
  'pos.customer_copy': 'Always Print Customer Copy',
  'pos.save_changes': 'Save Changes',
  'pos.discard_changes': 'Discard Changes',
  'pos.save_success': 'Receipt settings has been updated',
  'pos.order_settings_save_success': 'Order management has been updated',
  'pos.receipt_layout': 'Receipt Layout',
  'pos.inactive_timeout_5': '5 Minutes',
  'pos.inactive_timeout_10': '10 Minutes',
  'pos.inactive_timeout_15': '15 Minutes',
  'pos.inactive_timeout_20': '20 Minutes',
  'pos.inactive_timeout_30': '30 Minutes',
  'pos.inactive_timeout_45': '45 Minutes',
  'pos.inactive_timeout_60': '1 Hour',
  'pos.order-management': 'Order Management',
  'pos.configure_register': 'Configure Register',
  'pos.configure_register_description':
    'Customize the Register screen to fit your venue’s workflow',
  'pos.cash_bar_toggle_label': 'Include Quick Cash buttons on the Register Screen',
  'pos.cash_bar_toggle_description':
    'These buttons enable staff to take cash orders in 1-tap, and are helpful for venues that make many cash transactions.',
  'pos.order_management_info':
    'Configure additional options around the movement of orders, from start to finish, on the POS (Point of Sale)',
  'pos.toggle_order_state': 'Enable "Ready" Order State',
  'pos.toggle_order_state_info':
    'This setting is useful if your venue operates a pickup counter or you would like to track when orders are collected',
  'pos.preparation_time_info_error': 'Preparation time must be between 1 and 120',
  'pos.preparation_time': 'Preparation time (in minutes)',
  'pos.preparation_time_info': 'value must be between 1 and 120',
  'pos.inactivity_window': 'Inactivity Window',
  'pos.moving_orders': 'Moving orders through the “In Progress” state',
  'pos.moving_orders_info':
    'Controls how orders move out of the “in progress” state manually (by the user) or automatically (by configured prep time)',
  'pos.inactivity_window_info':
    'The amount of time that the POS (Point of Sale) will remain unlocked before returning to the PIN screen, after a period of inactivity',
  'pos.moving_orders_manually': 'Manually',
  'pos.moving_orders_automatically': 'Automatically (based on PreparationTime)',
  'pos.moving_orders_note':
    'Note: Unpaid orders cannot move to “Closed” unless they are “Cancelled”. If the “Ready” tab has been disabled, orders will remain in the “In Progress” tab beyond the preparation time limit, until paid in full or cancelled manually by the user.',
  'pos.receipt_layout_info':
    'Select which optional fields appear on the printed Merchant and Customer receipts',
  'pos.venue_address': 'Venue Address',
  'pos.email_address': 'Email Address',
  'pos.phone_number': 'Phone Number',
  'pos.order_type': 'Order Type',
  'pos.server': 'Server',
  'pos.tax': 'Tax Numbers',
  'pos.footer_notes': 'Footer Notes',
  'pos.cash': 'Cash',
  'pos.non_integrated': 'Non-Integrated',
  'pos.order_ticket_number': 'Order Ticket Number',
  'pos.integrated': 'Integrated',
  'pos.order_settings_saved_failure': 'There was an error saving Order Management',
  'branding.branding': 'Branding',
  'branding.icon_header': 'Logo',
  'branding.icon_subtitle': 'Upload your logo to provide a consistent diner experience.',
  'branding.banner_header': 'Banner Image',
  'branding.banner_subtitle': 'Upload a banner image to showcase your menu or dining experience.',
  'branding.color_theme_header': 'Color Theme',
  'branding.color_theme_subtitle': `Select or input your brand's primary and secondary colors.`,
  'branding.color_theme_live_preview': 'Live Preview',
  'branding.color_primary_color_label': 'Primary Color',
  'branding.color_secondary_color_label': 'Secondary Color',
  'branding.color_primary_button': 'Primary Button',
  'branding.color_link_button': 'Link and icon',
  'branding.color_secondary_button': 'Secondary Button',
  'branding.color_tertiary_button': 'Tertiary Button',
  'branding.color_button_fails_wcag_contrast':
    'This color may be difficult for your diners to see.',
  'branding.color_white_error_primary': 'Primary color cannot be white (#FFFFFF).',
  'branding.color_white_error_secondary': 'Secondary color cannot be white (#FFFFFF).',
  'branding.same_color_error_primary':
    'Primary color cannot use the same #HEX as the secondary color.',
  'branding.same_color_error_secondary':
    'Secondary color cannot use the same #HEX as the primary color.',
  'branding.settings_saved_success': 'Branding settings saved successfully',
  'branding.settings_saved_failure': 'There was an error saving branding settings',
  'settings.settings': 'Settings',
  'hours_scheduling.hours_scheduling': 'Hours & Scheduling',
  'hours_scheduling.configure_day_parts': 'Configure Day Parts',
  'hours_scheduling.configure_day_parts_description':
    'Create and organize your day parts to quickly filter between defined business times and see them in your reports.',
  'hours_scheduling.no_day_parts_found': 'You currently don’t have any day parts',
  'hours_scheduling.hours_of_operation': 'Hours of Operation',
  'hours_scheduling.hours_of_operation_desc': 'Indicate when you are open for in-venue service.',
  'hours_scheduling.add_hours': 'Add Hours',
  'hours_scheduling.start_time': 'Start Time',
  'hours_scheduling.end_time': 'End Time',
  'hours_scheduling.hours_of_operation.days_error_message':
    'Please select the day(s) this hours of operation is available.',
  'hours_scheduling.hours_of_operation.exceptions': 'Exceptions',
  'hours_scheduling.hours_of_operation.exceptions.desc':
    'Days and times you are open for a special occasion, event, holiday, or other reason. Exceptions will override your normal Hours of Operation for the specified day(s).',
  'hours_scheduling.hours_of_operation.exceptions.add': 'Add Exception',
  'hours_scheduling.hours_of_operation.closures': 'Closures',
  'hours_scheduling.hours_of_operation.closures.desc':
    'Days and times you are closed for holidays, private events, renovations, or other reasons. Closures will override your regular Hours of Operation and any Exceptions you have created for the specified day(s).',
  'hours_scheduling.hours_of_operation.closures.add': 'Add Closure',
  'hours_scheduling.hours_of_operation.date': 'Date',
  'hours_scheduling.hours_of_operation.start_and_end_time': 'Start & End Time',
  'hours_scheduling.hours_of_operation.exceptions.empty_message':
    'You currently have no Exceptions to be displayed.',
  'hours_scheduling.hours_of_operation.closures.empty_message':
    'You currently have no Closures to be displayed.',
  'hours_scheduling.hours_of_operation.exceptions.delete': 'The exception was deleted successfully',
  'hours_scheduling.hours_of_operation.closures.delete': 'The closure was deleted successfully',
  'hours_scheduling.hours_of_operation.exceptions.delete_title':
    'Are you sure you want to delete the exception on {{date}}?',
  'hours_scheduling.hours_of_operation.exceptions.delete_confirm_btn': 'Delete Exception',
  'hours_scheduling.hours_of_operation.closures.delete_title':
    'Are you sure you want to delete the closure on {{date}}?',
  'hours_scheduling.hours_of_operation.closures.delete_confirm_btn': 'Delete Closure',
  'hours_scheduling.hours_of_operation.exceptions_closures_subtitle':
    'This action is irreversible.',
  'hours_scheduling.add_exception': 'Add Exception',
  'hours_scheduling.edit_exception': 'Edit Exception',
  'hours_scheduling.add_closure': 'Add Closure',
  'hours_scheduling.edit_closure': 'Edit Closure',
  'hours_scheduling.when': 'When',
  'hours_scheduling.all_day': 'All Day',
  'hours_scheduling.exception_added_success': 'Exception was added successfully',
  'hours_scheduling.exception_updated_success': 'Exception was updated successfully',
  'hours_scheduling.closure_added_success': 'Closure was added successfully',
  'hours_scheduling.closure_updated_success': 'Closure was updated successfully',
  'hours_scheduling.hours_of_operation.conflict_service_time':
    'Heads up! The highlighted Hours of Operation below do not fit within your Service Time window of {{startTime}} to {{endTime}}.',
  'hours_scheduling.service_time.conflict_message':
    'Heads up! You have Hours of Operation that do not fit within your Service Time window. Your reports may be inaccurate.',
  'venue_details.venue_details': 'Venue Details',
  'venue_details.basic_details': 'Basic Details',
  'venue_details.contact': 'Contact Details',
  'venue_details.online_presence': 'Online Presence',
  'venue_details.venue_name': 'Venue Name',
  'venue_details.venue_id': 'Venue ID',
  'venue_details.business_number': 'Business Number',
  'venue_details.currency': 'Currency',
  'venue_details.currency_placeholder': 'Select a currency',
  'venue_details.business_description': 'Business Description',
  'venue_details.business_description_placeholder': "Enter a description of what you're known for",
  'venue_details.specialty': 'Specialty',
  'venue_details.specialty_placeholder': 'Enter your Specialty',
  'venue_details.parking_info': 'Parking Info',
  'venue_details.dress_code': 'Dress Code',
  'venue_details.categories_and_cuisine_types': 'Categories and Cuisine Types',
  'venue_details.optional_sublabel': 'Optional',
  'venue_details.specialty_length_restrict': 'Oops! Specialty has to be 30 characters or less.',
  'venue_details.items_selected': '{{count}} Selected',
  'venue_details.select_option': 'Select Option',
  'venue_details.select-options': 'Select Options',
  'venue_details.save_success': 'Venue details saved successfully!',
  'venue_details.save_failed': 'An error occurred while saving venue details',
  'venue_details.warning.title': 'Are you sure you want to change this information?',
  'venue_details.warning.body':
    "<2>Changing the <1>venue name, business number, or country</1>, could impact the venue's TouchBistro Payments account and existing integrations (i.e. DoorDash Drive), if applicable. Please ensure these changes are also applied to the venue's TouchBistro Payments account and communicated to any applicable 3rd parties.</2>",
  'venue_details.address_line_1': 'Address',
  'venue_details.address_line_2': 'Apartment, Suite, etc.',
  'venue_details.city': 'City',
  'venue_details.state_province': 'State/Province',
  'venue_details.state_province.length_error': 'Must be less than 26 characters',
  'venue_details.state_province.pattern_error':
    'Must look like ON, NY, or other 2 letter state/province code',
  'venue_details.zip_postal_code': 'Zip/Postal Code',
  'venue_details.country': 'Country',
  'venue_details.country.error':
    "Unfortunately, you cannot change the address's country. Please reach out to support if this is needed.",
  'venue_details.email': 'Email',
  'venue_details.email.pattern_error':
    'Email must be a valid email address. E.g.: example@example.com',
  'venue_details.phone': 'Phone',
  'venue_details.your_website': 'Your Website',
  'venue_details.menu_links': 'Menu Links',
  'venue_details.social_media': 'Social Media',
  'venue_details.enter_a_valid_url': 'Please enter a valid url.',
  'venue_details.enter_a_url': 'Please enter a url.',
  'venue_details.enter_a_name': 'Please enter a name.',
  'venue_details.menu_link_name_restrict': 'Oops! Menu Link Name has to be 30 characters or less.',
  'venue_details.social_media_name_restrict':
    'Oops! Custom Social Media Label has to be 30 characters or less.',
  'venue_details.enter_name': 'Enter Name',
  'venue_details.select_type': 'Select Type',
  'venue_details.select_type_required': 'Please select a type',
  'venue_details.custom_social_media': 'Custom Social Media Type Here',
  'venue_details.add_menu_link': 'Add Menu Link',
  'venue_details.add_social_media': 'Add Social Media',
  'venue_details.parkingInfo.none': 'No Parking',
  'venue_details.parkingInfo.street': 'Street Parking',
  'venue_details.parkingInfo.lot': 'Lot Parking',
  'venue_details.parkingInfo.pay': 'Pay Parking',
  'venue_details.parkingInfo.valet': 'Valet Parking',
  'venue_details.dressCode.casual': 'Casual',
  'venue_details.dressCode.business_casual': 'Business Casual',
  'venue_details.dressCode.jacket_preferred': 'Jacket Preferred',
  'venue_details.dressCode.jacket_required': 'Jacket Required',
  'venue_details.dressCode.jacket_and_tie_required': 'Jacket & Tie Required',
  'venue_details.dressCode.formal_attire': 'Formal Attire',
  'venue_details.cuisineTypes.african': 'African',
  'venue_details.cuisineTypes.algerian': 'Algerian',
  'venue_details.cuisineTypes.american': 'American',
  'venue_details.cuisineTypes.argentina': 'Argentina',
  'venue_details.cuisineTypes.asian': 'Asian',
  'venue_details.cuisineTypes.barbecue': 'Barbecue',
  'venue_details.cuisineTypes.belgian': 'Belgian',
  'venue_details.cuisineTypes.bistro': 'Bistro',
  'venue_details.cuisineTypes.brasserie': 'Brasserie',
  'venue_details.cuisineTypes.brazilian': 'Brazilian',
  'venue_details.cuisineTypes.breakfast': 'Breakfast',
  'venue_details.cuisineTypes.bring_your_own_wine': 'Bring your own wine',
  'venue_details.cuisineTypes.brunch': 'Brunch',
  'venue_details.cuisineTypes.cajun_creole': 'Cajun Creole',
  'venue_details.cuisineTypes.canadian': 'Canadian',
  'venue_details.cuisineTypes.caribbean': 'Caribbean',
  'venue_details.cuisineTypes.casual_fine_dining': 'Casual fine dining',
  'venue_details.cuisineTypes.charcuterie': 'Charcuterie',
  'venue_details.cuisineTypes.chinese': 'Chinese',
  'venue_details.cuisineTypes.cocktail': 'Cocktail',
  'venue_details.cuisineTypes.coffee': 'Coffee',
  'venue_details.cuisineTypes.communal_seating': 'Communal seating',
  'venue_details.cuisineTypes.contemporary_cuisine': 'Contemporary cuisine',
  'venue_details.cuisineTypes.continental': 'Continental',
  'venue_details.cuisineTypes.craft_beer': 'Craft beer',
  'venue_details.cuisineTypes.craft_cider': 'Craft cider',
  'venue_details.cuisineTypes.creperie': 'Creperie',
  'venue_details.cuisineTypes.cuban': 'Cuban',
  'venue_details.cuisineTypes.deli': 'Deli',
  'venue_details.cuisineTypes.desserts_and_sweets': 'Desserts and sweets',
  'venue_details.cuisineTypes.eastern_european': 'Eastern European',
  'venue_details.cuisineTypes.ethiopian': 'Ethiopian',
  'venue_details.cuisineTypes.family_friendly': 'Family friendly',
  'venue_details.cuisineTypes.filipino': 'Filipino',
  'venue_details.cuisineTypes.fine_dining': 'Fine dining',
  'venue_details.cuisineTypes.fish': 'Fish',
  'venue_details.cuisineTypes.fondue': 'Fondue',
  'venue_details.cuisineTypes.french': 'French',
  'venue_details.cuisineTypes.french_bistro': 'French bistro',
  'venue_details.cuisineTypes.generic': 'Generic',
  'venue_details.cuisineTypes.german': 'German',
  'venue_details.cuisineTypes.gluten_free_cooking': 'Gluten free cooking',
  'venue_details.cuisineTypes.greek': 'Greek',
  'venue_details.cuisineTypes.grill': 'Grill',
  'venue_details.cuisineTypes.halal': 'Halal',
  'venue_details.cuisineTypes.hamburgers': 'Hamburgers',
  'venue_details.cuisineTypes.hungarian': 'Hungarian',
  'venue_details.cuisineTypes.indian': 'Indian',
  'venue_details.cuisineTypes.indonesian': 'Indonesian',
  'venue_details.cuisineTypes.international': 'International',
  'venue_details.cuisineTypes.irish': 'Irish',
  'venue_details.cuisineTypes.italian': 'Italian',
  'venue_details.cuisineTypes.italian_bistro': 'Italian bistro',
  'venue_details.cuisineTypes.jamaican': 'Jamaican',
  'venue_details.cuisineTypes.japanese': 'Japanese',
  'venue_details.cuisineTypes.jewish': 'Jewish',
  'venue_details.cuisineTypes.korean': 'Korean',
  'venue_details.cuisineTypes.kosher': 'Kosher',
  'venue_details.cuisineTypes.large_groups': 'Large groups',
  'venue_details.cuisineTypes.latin': 'Latin',
  'venue_details.cuisineTypes.lebanese': 'Lebanese',
  'venue_details.cuisineTypes.lunch': 'Lunch',
  'venue_details.cuisineTypes.market': 'Market',
  'venue_details.cuisineTypes.mediterranean': 'Mediterranean',
  'venue_details.cuisineTypes.mexican': 'Mexican',
  'venue_details.cuisineTypes.microbrewery': 'Microbrewery',
  'venue_details.cuisineTypes.middle_eastern': 'Middle Eastern',
  'venue_details.cuisineTypes.moroccan': 'Moroccan',
  'venue_details.cuisineTypes.nepalese': 'Nepalese',
  'venue_details.cuisineTypes.north_american': 'North American',
  'venue_details.cuisineTypes.outdoor': 'Outdoor',
  'venue_details.cuisineTypes.oyster_bar': 'Oyster bar',
  'venue_details.cuisineTypes.peruvian': 'Peruvian',
  'venue_details.cuisineTypes.pizzeria': 'Pizzeria',
  'venue_details.cuisineTypes.polish': 'Polish',
  'venue_details.cuisineTypes.portuguese': 'Portuguese',
  'venue_details.cuisineTypes.pretzels': 'Pretzels',
  'venue_details.cuisineTypes.pub': 'Pub',
  'venue_details.cuisineTypes.quebec': 'Quebec',
  'venue_details.cuisineTypes.regional': 'Regional',
  'venue_details.cuisineTypes.rotisserie': 'Rotisserie',
  'venue_details.cuisineTypes.russian': 'Russian',
  'venue_details.cuisineTypes.sandwiches': 'Sandwiches',
  'venue_details.cuisineTypes.sausages': 'Sausages',
  'venue_details.cuisineTypes.seafood': 'Seafood',
  'venue_details.cuisineTypes.smoked_meat': 'Smoked meat',
  'venue_details.cuisineTypes.spanish': 'Spanish',
  'venue_details.cuisineTypes.steak_house': 'Steak house',
  'venue_details.cuisineTypes.sugar_shack': 'Sugar shack',
  'venue_details.cuisineTypes.sushi': 'Sushi',
  'venue_details.cuisineTypes.switzerland': 'Switzerland',
  'venue_details.cuisineTypes.tapas': 'Tapas',
  'venue_details.cuisineTypes.tartares': 'Tartares',
  'venue_details.cuisineTypes.terroir': 'Terroir',
  'venue_details.cuisineTypes.thai': 'Thai',
  'venue_details.cuisineTypes.tunisian': 'Tunisian',
  'venue_details.cuisineTypes.turkish': 'Turkish',
  'venue_details.cuisineTypes.vegan': 'Vegan',
  'venue_details.cuisineTypes.vegetarian': 'Vegetarian',
  'venue_details.cuisineTypes.vietnamese': 'Vietnamese',
  'venue_details.cuisineTypes.west_coast': 'West Coast',
  'venue_details.cuisineTypes.wine_bar': 'Wine bar',
  service_fees: 'Service Fees',
  'service_fees.create': 'Create Service Fee',
  'service_fees.delivery': 'Delivery',
  'service_fees.delivery.definition':
    'Additional settings to trigger when the service fee is automatically applied to a delivery order',
  'service_fees.delivery.waive_fee_conditions':
    'Waive this fee if it’s more than or equal to this amount ($)',
  'service_fees.delivery.assign_to_boundary': 'Assign to an existing Delivery Boundary',
  'service_fees.delivery.boundaries': 'Delivery boundaries',
  'service_fees.delivery.boundary_rule':
    'must be configured before a service fee can be assigned to it.',
  'service_fees.details': 'Service Fee Details',
  'service_fees.details.definition':
    'Service fees are additional charges added to a customer’s order to help cover the cost of the services provided by your business. Service fees should not be based on payment methods like credit cards fees. If you’d like to add fees on credit card transactions please configure',
  'service_fees.details.surcharges': 'Surcharges',
  'service_fees.details.fee_amount': 'Fee Amount',
  'service_fees.details.fee_type': 'What is the fee type?',
  'service_fees.details.fee_type.fixed_percentage': 'Fixed Percentage',
  'service_fees.details.fee_type.fixed_amount': 'Fixed Amount',
  'service_fees.details.fee_type.open_amount': 'Open Amount',
  'service_fees.details.fee_when_to_apply': 'When would you like to apply the service fee?',
  'service_fees.details.fee_when_to_apply.post_discount': 'Post-Discount',
  'service_fees.details.fee_when_to_apply.pre_discount': 'Pre-Discount',
  'service_fees.details.fee_who_should_receive': 'Who should receive the service fee?',
  'service_fees.details.fee_who_should_receive.venue': 'The Venue',
  'service_fees.details.fee_who_should_receive.venue.description':
    'The service fee given to the venue will be reported as sales',
  'service_fees.details.fee_who_should_receive.employee': 'The Employee',
  'service_fees.details.fee_who_should_receive.employee.description':
    'The service fee given to the employee will be reported as a gratuity',
  'service_fees.details.label': 'Service Fee Name',
  'service_fees.details.label_required': 'Service Fee Name is required',
  'service_fees.details.amount_required': 'Service Fee Amount is required',
  'service_fees.form_percentage_amount_validation':
    'The service fee percentage must be between 0 - 100.',
  'service_fees.form_dollar_amount_validation': 'The service fee has limit of $1000',
  'service_fees.details.label_validation_length':
    'Service fee name must be within 1 - 60 characters',
  'service_fees.order_types': 'Order Types',
  'service_fees.order_types.instruction':
    'Choose which order types this service fee will automatically apply to.',
  'service_fees.order_types.manual': 'Manual',
  'service_fees.order_types.manual.description':
    'Manually add the service fee to an order (Cannot be applied with other order types)',
  'service_fees.order_types.dine_in': 'Dine-In',
  'service_fees.order_types.dine_in.description':
    'Automatically apply the service fee to every dine-in order',
  'service_fees.order_types.dine_in.settings':
    'Additional settings to trigger when the service fee is applied to a dine-in order',
  'service_fees.order_types.dine_in.min_party_size': 'Minimum party size',
  'service_fees.order_types.bar_tab': 'Bar Tab',
  'service_fees.order_types.bar_tab.description':
    'Automatically apply the service fee to every bar tab',
  'service_fees.order_types.pickup': 'Pickup / Takeout',
  'service_fees.order_types.pickup.description':
    'Automatically apply the service fee to every Pickup order (Excluding 3rd party apps)',
  'service_fees.order_types.delivery': 'Delivery',
  'service_fees.order_types.delivery.description':
    'Automatically apply the service fee to every delivery order (TB Online ordering & in-house)',
  'service_fees.taxes': 'Taxes',
  'service_fees.taxes.apply': 'Apply Tax?',
  'service_fees.taxes.definition':
    'Choose whether or not taxes should be applied to this service fee',
  'service_fees.taxes.apply.yes': 'Yes',
  'service_fees.taxes.apply.no': 'No',
  service_fee_name: 'Service Fee',
  service_fee_type: 'Type',
  service_fee_amount: 'Amount',
  service_fee_fulfillment: 'Fulfillment',
  service_fee_taxes: 'Taxes',
  service_fee_status: 'Status',
  'service_fee.empty.message': "You currently don't have any Service Fees",
  'service_fee.delete_modal.title': "Are you sure you want to delete \n'{{label}}'?",
  'service_fee.delete.modal.message': 'Deleting this service will not affect your past reports',
  'service_fee.delete.button.message': 'Delete Service Fee',
  'service_fees.taxes.select': 'Select the taxes that will be applied to the service fee',
  'service_fees.oo.configure_link':
    '<2>View <1>Service Fees</1> to configure your delivery fees</2>',
}

export default venueDetailsEN
